import logo from './assets/tassanaik_logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="warn-text">
          coming soon..
        </p>        
      </header>
    </div>
  );
}

export default App;
